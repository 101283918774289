import {
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	Link,
	Typography,
} from "@mui/material";
import { storage } from "../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import { useEffect, useState } from "react";
import ReactAudioPlayer from "react-audio-player";

export const ItBegins = () => {
	const [loading, setLoading] = useState(true);
	const [coverImgURL, setCoverImgURL] = useState("");
	const [wavURL, setWavURL] = useState("");

	useEffect(() => {
		const storageRef = ref(storage, "itBegins/cover.jpg");
		getDownloadURL(storageRef).then((url) => {
			setCoverImgURL(url);
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		const storageRef = ref(storage, "itBegins/It Begins.wav");
		getDownloadURL(storageRef).then((url) => {
			setWavURL(url);
		});
	}, []);

	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			minWidth="300px"
			minHeight="100vh"
		>
			<Card title="It Begins" sx={{ maxWidth: 500 }}>
				<CardContent
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<img
						src={coverImgURL}
						onLoad={() => {
							setLoading(false);
						}}
						height="100%"
						width="100%"
						alt="thats me"
						style={{
							overflow: "hidden",
							backgroundPosition: "center center",
							objectFit: "cover",
							opacity: loading ? 0 : 1,
							paddingBottom: 1,
						}}
					/>
					<Box
						display="flex"
						justifyContent="center"
						flexDirection="column"
						alignItems="center"
					>
						<Typography
							gutterBottom
							variant="h5"
							component="div"
							sx={{ paddingY: 1 }}
						>
							It Begins
						</Typography>
						<ReactAudioPlayer src={wavURL} controls />
						<Box py={2}>
							<Typography
								gutterBottom
								variant="body2"
								textAlign="center"
							>
								Releases on may 31st but here just for you!
							</Typography>
							<Typography
								gutterBottom
								variant="body2"
								textAlign="center"
							>
								Download it using the ellipsis icon
							</Typography>
						</Box>
					</Box>
					<Typography gutterBottom variant="h5" textAlign="center">
						Find it here when it's released
					</Typography>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Link
								href="https://leolilley.bandcamp.com/"
								underline="hover"
								target="_blank"
								rel="noopener"
							>
								<Button
									variant="contained"
									color="inherit"
									size="large"
									fullWidth
								>
									BANDCAMP
								</Button>
							</Link>
						</Grid>
						<Grid item xs={12}>
							<Link
								href="https://open.spotify.com/artist/15re5mGlnEReWZfq93h4ze"
								underline="hover"
								target="_blank"
								rel="noopener"
							>
								<Button
									variant="contained"
									color="inherit"
									size="large"
									fullWidth
								>
									SPOTIFY
								</Button>
							</Link>
						</Grid>
						<Grid item xs={12}>
							<Link
								href="https://soundcloud.com/leolilley"
								underline="hover"
								target="_blank"
								rel="noopener"
							>
								<Button
									variant="contained"
									color="inherit"
									size="large"
									fullWidth
								>
									SOUNDCLOUD
								</Button>
							</Link>
						</Grid>
						<Grid item xs={12}>
							<Link
								href="https://leolilley.bandcamp.com/"
								underline="hover"
								target="_blank"
								rel="noopener"
							>
								<Button
									variant="contained"
									color="inherit"
									size="large"
									fullWidth
								>
									APPLE MUSIC
								</Button>
							</Link>
						</Grid>
						<Grid item xs={12}>
							<Link
								href="https://leolilley.bandcamp.com/"
								underline="hover"
								target="_blank"
								rel="noopener"
							>
								<Button
									variant="contained"
									color="inherit"
									size="large"
									fullWidth
								>
									YOUTUBE MUSIC
								</Button>
							</Link>
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</Box>
	);
};
