import { createTheme, responsiveFontSizes } from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface Theme {
		status: {
			danger: string;
		};
	}
	// allow configuration using `createTheme`
	interface ThemeOptions {
		status?: {
			danger?: string;
		};
	}
	interface Palette {
		neutral: Palette["primary"];
		backgroundLight: Palette["background"];
		backgroundHeader: Palette["background"];
		border: Palette["primary"];
		textLandingPage: Palette["primary"];
	}
	// allow configuration using `createTheme`
	interface PaletteOptions {
		neutral?: PaletteOptions["primary"];
		backgroundLight?: PaletteOptions["background"];
		backgroundHeader?: PaletteOptions["background"];
		border?: PaletteOptions["primary"];
		textLandingPage?: PaletteOptions["primary"];
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		h6Bold?: React.CSSProperties;
		body1Bold?: React.CSSProperties;
	}
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
	interface TypographyPropsVariantOverrides {
		h6Bold: true;
		body1Bold: true;
	}
}

export const DefaultTheme = responsiveFontSizes(
	createTheme({
		palette: {
			primary: {
				main: "#707070",
				light: "#F1F1F1",
				dark: "#404040",
				contrastText: "white",
			},
			background: {
				default: "#F2F2F4",
			},
			backgroundLight: {
				default: "#FCF6F2",
			},
			backgroundHeader: {
				default: "rgba(255,255,255)",
			},
			border: {
				main: "#E0E0E0",
			},
		},
		typography: {
			allVariants: {
				fontFamily: "Roboto Mono",
				textTransform: "none",
			},
		},
		components: {
			MuiCssBaseline: {
				styleOverrides: {
					"::-webkit-scrollbar": {
						width: "8px",
						backgroundColor: "#F1F1F1",
					},
					"::-webkit-scrollbar-thumb": {
						backgroundColor: "#404040",
						borderRadius: "8px",
					},
					"::-webkit-scrollbar-corner": {
						backgroundColor: "#F1F1F1",
					},
				},
			},
		},
	})
);
