import { Box, Grid, Typography } from "@mui/material";
import me from "../../images/me2.jpg";
import { useScreenSize } from "../customHooks/useScreenSize";

interface SectionProps {
	barHeight: number;
	ltSmall: boolean;
	padding: number;
}

export const Bio = ({ barHeight, ltSmall, padding }: SectionProps) => {
	const [_, height] = useScreenSize();
	return (
		<Box
			minHeight={height - barHeight}
			display="flex"
			id="BIO"
			padding={padding}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Grid item xs={12}>
						<Typography variant="h2">BIO</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						display="flex"
						justifyContent="center"
						paddingTop={ltSmall ? 4 : 12}
						flexDirection="column"
					>
						<Typography
							variant="subtitle2"
							fontSize={ltSmall ? 20 : 24}
						>
							I'm an indie musician / full send developer ripping
							and rocking it in the deep south of Otepoti,
							Aotearoa.
						</Typography>
						<Typography fontSize={ltSmall ? 18 : 20} paddingTop={2}>
							I rate sending some crack-up antics and high-energy
							shows. Having been a big fan of Mac DeMarco, I’m out
							here tryna blend his live spirit with the classic
							sound of The Strokes, all while deeply influenced by
							New Zealand's musical culture. Originally from
							Wellington, I cut my teeth playing flat parties, jam
							nights, and pub gigs. Now, with my own band and the
							experience to prove it, we're laying down what I
							love to cook up.
						</Typography>
						<Typography
							variant="body1"
							fontSize={ltSmall ? 18 : 20}
							paddingTop={4}
						>
							I made this website too, minimalism all the way.
						</Typography>
						<Typography
							variant="body1"
							fontSize={ltSmall ? 18 : 20}
						>
							Especially when you suck at design.
						</Typography>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					md={6}
					display="flex"
					justifyContent="center"
					alignItems="center"
					maxHeight={height - barHeight}
					paddingBottom={8}
				>
					<img
						src={me}
						onLoad={() => {
							//setLoading(false);
						}}
						height="100%"
						width="100%"
						alt="thats me again!"
						style={{
							overflow: "hidden",
							backgroundPosition: "center center",
							objectFit: "contain",
							//opacity: loading ? 0 : 1,
							transition: "opacity 0.5s ease",
						}}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};
