import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import { useState } from "react";
import { firestore } from "../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { LoadingButton } from "@mui/lab";

interface MailListDialogProps {
	isOpen: boolean;
	handleClose: () => void;
}

export const MailListDialog = ({
	isOpen,
	handleClose,
}: MailListDialogProps) => {
	const [loading, setLoading] = useState(false);
	const [loadingButtonText, setLoadingButtonText] = useState("JOIN!!!");
	const [name, setName] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [phoneNumber, setPhoneNumber] = useState<string>("");
	const [errorMap, setErrorMap] = useState({
		name: "",
		email: "",
		phoneNumber: "",
	});

	const isValidEmail = (email: string) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	};

	const isNumeric = (str: string) => {
		return /^\d+$/.test(str);
	};

	const validateInputs = () => {
		const newErrors = {
			name: "",
			email: "",
			phoneNumber: "",
		};
		if (name === "") {
			newErrors.name = "I gotta know what to call you!";
		}
		if (email === "" || !isValidEmail(email)) {
			newErrors.email = "That email doesn't seem quite right to me aye";
		}
		if (!isNumeric(phoneNumber) && phoneNumber !== "") {
			newErrors.phoneNumber = "Optional but its gonna be numbers only";
		}
		setErrorMap(newErrors);
		if (
			newErrors.name === "" &&
			newErrors.email === "" &&
			newErrors.phoneNumber === ""
		)
			return true;
		else return false;
	};

	const handleJoin = async () => {
		if (validateInputs()) {
			setLoading(true);
			try {
				const info = {
					name: name,
					email: email,
					phoneNumber: phoneNumber,
				};
				await setDoc(doc(firestore, "mailList", email), info);
				setLoadingButtonText("JOINED!!!");
			} catch (e) {
				console.error("Error adding document: ", e);
			}
			setLoading(false);
		}
	};

	return (
		<Dialog onClose={handleClose} open={isOpen}>
			<DialogTitle>Sign Up To The Mail List!</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography>
							Get updates about shit I'm doing! Mostly just helps
							me feel like I've got some fans out there.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							label="Name"
							fullWidth
							value={name}
							onChange={(
								event: React.ChangeEvent<HTMLInputElement>
							) => {
								setErrorMap((prevState) => ({
									...prevState,
									name: "",
								}));
								setName(event.target.value);
							}}
							error={errorMap.name !== ""}
							helperText={errorMap.name}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							label="Email"
							fullWidth
							type="email"
							value={email}
							onChange={(
								event: React.ChangeEvent<HTMLInputElement>
							) => {
								setErrorMap((prevState) => ({
									...prevState,
									email: "",
								}));
								setEmail(event.target.value);
							}}
							error={errorMap.email !== ""}
							helperText={errorMap.email}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							color="primary"
							label="Phone Number (Optional)"
							value={phoneNumber}
							fullWidth
							onChange={(
								event: React.ChangeEvent<HTMLInputElement>
							) => {
								setErrorMap((prevState) => ({
									...prevState,
									phoneNumber: "",
								}));
								setPhoneNumber(event.target.value);
							}}
							error={errorMap.phoneNumber !== ""}
							helperText={errorMap.phoneNumber}
						/>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					paddingTop={2}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Box>
						<Typography variant="subtitle2">
							No one has actually joined lmao
						</Typography>
						<Typography variant="subtitle2">
							You could be my first?
						</Typography>
					</Box>
					<LoadingButton
						variant="contained"
						color="inherit"
						size="large"
						loading={loading}
						onClick={handleJoin}
					>
						{loadingButtonText}
					</LoadingButton>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};
