import * as Scroll from "react-scroll";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { useScrollHandler } from "./customHooks/useScrollHandler";
import { useScreenSize } from "./customHooks/useScreenSize";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";

interface TopBarProps {
	sections: string[];
	setCurrentBarHeight: Dispatch<SetStateAction<number>>;
	setSmallBarHeight: Dispatch<SetStateAction<number>>;
	barHeight: number;
	pastTop: boolean;
	ltSmall: boolean;
	loading: boolean;
	offset: number;
}

export const TopBar = ({
	sections,
	setCurrentBarHeight,
	setSmallBarHeight,
	barHeight,
	ltSmall,
	loading,
	offset,
}: TopBarProps) => {
	const pastTop = true;
	const scroll = useScrollHandler();
	const [width, height] = useScreenSize();

	const barRef = useRef<HTMLDivElement>(null);

	const calculateUnderlineStyle = (index: number) =>
		scroll >= height * (index + 1) - barHeight * (index + 1) - barHeight &&
		scroll < height * (index + 2) - barHeight * (index + 2) - barHeight;

	useEffect(() => {
		if (barRef.current) {
			setSmallBarHeight(barRef.current.clientHeight);
		}
	}, [width, setSmallBarHeight]);

	useEffect(() => {
		if (barRef.current) {
			setCurrentBarHeight(barRef.current.clientHeight);
		}
	}, [scroll, width, loading, setCurrentBarHeight]);

	return (
		<AppBar
			component="nav"
			elevation={0}
			style={{
				background: pastTop ? "black" : "none",
				padding: pastTop ? 20 : 32,
				transition: "background-color 0.5s ease",
			}}
			ref={barRef}
		>
			<Toolbar>
				<Grid container justifyContent="center" spacing={2}>
					<Grid
						item
						md={4}
						lg={pastTop ? 4 : 8}
						xl={pastTop ? 4 : 9}
						display="flex"
						flexDirection={
							pastTop ? "row" : { sm: "row", md: "column" }
						}
					>
						<Typography
							variant={pastTop ? "h4" : "h2"}
							color={pastTop ? "white" : "black"}
							fontWeight={500}
						>
							LEO&nbsp;
						</Typography>
						<Typography
							variant={pastTop ? "h4" : "h2"}
							color={pastTop ? "white" : "black"}
							fontFamily="Roboto Mono"
							fontWeight={500}
						>
							LILLEY
						</Typography>
					</Grid>
					<Grid
						container
						item
						md={8}
						lg={pastTop ? 8 : 4}
						xl={pastTop ? 8 : 3}
						alignItems="flex-start"
						justifyContent="flex-end"
						spacing={pastTop ? 0 : 1}
					>
						{sections.map((item, index) => (
							<Grid
								item
								xs={4}
								sm={pastTop ? 2 : 4}
								justifyContent="center"
								display="flex"
								key={item}
							>
								<Scroll.Link
									to={item}
									smooth="true"
									offset={
										pastTop
											? -barHeight
											: -(barHeight - offset)
									}
								>
									<Button fullWidth>
										<Typography
											variant={
												pastTop
													? ltSmall
														? "subtitle2"
														: "h6"
													: "h4"
											}
											color={pastTop ? "white" : "black"}
											fontWeight={400}
											sx={{
												textDecoration:
													scroll >=
														height * (index + 1) -
															barHeight *
																(index + 1) -
															barHeight &&
													scroll <
														height * (index + 2) -
															barHeight *
																(index + 2) -
															barHeight &&
													!ltSmall
														? "underline"
														: "none",
											}}
										>
											{item}
										</Typography>
									</Button>
								</Scroll.Link>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};
