import {
	Box,
	Button,
	CircularProgress,
	Grid,
	List,
	ListItem,
	Typography,
} from "@mui/material";
import { useScreenSize } from "../customHooks/useScreenSize";
import { getCollection } from "../../firebase";
import { useEffect, useState } from "react";

interface SectionProps {
	barHeight: number;
	ltSmall: boolean;
	padding: number;
}

type Gig = {
	header: string;
	text: string;
	url: string;
	date: string;
};

export const Gigs = ({ barHeight, ltSmall, padding }: SectionProps) => {
	const [_, height] = useScreenSize();
	const [loading, setLoading] = useState(true);
	const [gigs, setGigs] = useState<Gig[]>([]);

	useEffect(() => {
		const getGigs = async () => {
			setGigs(await getCollection<Gig>("gigs"));
			setLoading(false);
		};
		getGigs();
	}, []);

	return (
		<Box
			minHeight={height - barHeight}
			padding={padding}
			display="flex"
			flexDirection="column"
			id="GIGS"
		>
			<Typography variant="h2">GIGS</Typography>
			<Box
				display="flex"
				flex={1}
				marginTop={4}
				border="1px solid black"
				justifyContent="center"
				alignItems="center"
			>
				{loading ? (
					<CircularProgress size="4rem" />
				) : (
					<List sx={{ width: "80%" }}>
						{gigs.map(
							(gigData, index): JSX.Element => (
								<ListItem
									key={index}
									disablePadding
									sx={{ mb: 2, width: "100%" }}
								>
									<Button
										sx={{
											width: "100%",
											justifyContent: "center",
										}}
										onClick={() =>
											gigData.url !== ""
												? window.open(
														gigData.url,
														"_blank"
												  )
												: alert(
														"Event page not up yet!"
												  )
										}
									>
										<List>
											<Typography
												variant="h5"
												color="black"
												fontWeight="500"
												sx={{
													textDecoration: "underline",
												}}
											>
												{gigData.header}
											</Typography>
											<Typography
												variant="h6"
												color="black"
												fontWeight="400"
											>
												{gigData.text}
											</Typography>
											<Typography
												variant="h6"
												color="black"
												fontWeight="400"
											>
												{gigData.date}
											</Typography>
										</List>
									</Button>
								</ListItem>
							)
						)}
					</List>
				)}
				{/* <Typography variant="subtitle1" fontSize={20} color="black">
					No Gigs at the moment but I'll be hustling some up soon. The
					band is still coming together! When it's on they'll be right
					between these lines.
				</Typography> */}
			</Box>
		</Box>
	);
};
