import * as Scroll from "react-scroll";
import {
	Box,
	ButtonGroup,
	IconButton,
	Link,
	Paper,
	Stack,
	Tooltip,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useState } from "react";
import { MusicLinksDialog } from "./dialogs/MusicLinksDialog";
import { MailListDialog } from "./dialogs/MailListDialog";

interface FloatingLinksProps {
	pastTop: Boolean;
	isSmall: Boolean;
}

export const FloatingLinks = ({ pastTop, isSmall }: FloatingLinksProps) => {
	const [mailDialogOpen, setMailDialogOpen] = useState<boolean>(false);
	const [musicDialogOpen, setMusicDialogOpen] = useState<boolean>(false);
	const scroller = Scroll.animateScroll;

	return (
		<>
			{mailDialogOpen && (
				<MailListDialog
					isOpen={mailDialogOpen}
					handleClose={() => {
						setMailDialogOpen(false);
					}}
				/>
			)}
			<MusicLinksDialog
				isOpen={musicDialogOpen}
				handleClose={() => {
					setMusicDialogOpen(false);
				}}
			/>
			<Stack
				position="fixed"
				width="100%"
				bottom={0}
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				direction="row"
				zIndex={10}
			>
				<Box
					width="auto"
					marginLeft={isSmall ? 2 : 4}
					marginY={4}
					marginRight={1}
					visibility={pastTop ? "visible" : "hidden"}
					sx={{
						opacity: pastTop ? 1 : 0,
						transition: "opacity 0.5s ease",
					}}
				>
					<Paper
						sx={{
							backgroundColor: "primary.light",
							borderRadius: 10,
							padding: 0.5,
						}}
						elevation={4}
					>
						<Tooltip title="Scoll To Top">
							<IconButton
								size={isSmall ? "small" : "large"}
								sx={{
									padding: 2,
								}}
								onClick={() => scroller.scrollToTop()}
							>
								<KeyboardDoubleArrowUpIcon fontSize="inherit" />
							</IconButton>
						</Tooltip>
					</Paper>
				</Box>
				<Box
					width="auto"
					marginRight={isSmall ? 2 : 4}
					marginLeft={1}
					marginY={4}
				>
					<Paper
						sx={{
							backgroundColor: "primary.light",
							paddingX: isSmall ? 1 : 2,
							paddingY: 1,
							transition: "background-color 0.2s ease",
						}}
						elevation={pastTop ? 4 : 8}
					>
						<ButtonGroup>
							<Tooltip title="the gram">
								<Link
									href="https://www.instagram.com/leo.lilley_/"
									underline="hover"
									target="_blank"
									rel="noopener"
								>
									<IconButton
										size={isSmall ? "medium" : "large"}
									>
										<InstagramIcon
											fontSize="inherit"
											color="inherit"
										/>
									</IconButton>
								</Link>
							</Tooltip>
							<Tooltip title="My music?!?">
								<IconButton
									size={isSmall ? "medium" : "large"}
									onClick={() => setMusicDialogOpen(true)}
								>
									<AudiotrackIcon fontSize="inherit" />
								</IconButton>
							</Tooltip>
							<Tooltip title="Youtube???">
								<Link
									href="https://www.youtube.com/channel/UCoC_tpFLfX_R1KQJm7UKxdg"
									underline="hover"
									target="_blank"
									rel="noopener"
								>
									<IconButton
										size={isSmall ? "medium" : "large"}
									>
										<YouTubeIcon fontSize="inherit" />
									</IconButton>
								</Link>
							</Tooltip>
							<Tooltip title="JOIN THE MAIL LIST!!!">
								<IconButton
									size={isSmall ? "medium" : "large"}
									onClick={() => setMailDialogOpen(true)}
								>
									<EmailIcon fontSize="inherit" />
								</IconButton>
							</Tooltip>
							<Tooltip title="the zucc site">
								<Link
									href="https://www.facebook.com/people/Leo-Lilley-Music/100092443847708/"
									underline="hover"
									target="_blank"
									rel="noopener"
								>
									<IconButton
										size={isSmall ? "medium" : "large"}
									>
										<FacebookIcon fontSize="inherit" />
									</IconButton>
								</Link>
							</Tooltip>
						</ButtonGroup>
					</Paper>
				</Box>
			</Stack>
		</>
	);
};
