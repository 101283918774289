import { useEffect, useState } from "react";
import { getCollection } from "../firebase";
import {
	Box,
	Button,
	ButtonGroup,
	Grid,
	IconButton,
	Link,
	List,
	ListItem,
	Paper,
	Tooltip,
	Typography,
} from "@mui/material";
import me from "../images/me3.jpg";
import backgroundImage from "../images/leoheadsand5.jpg";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import { MailListDialog } from "./dialogs/MailListDialog";
import { MusicLinksDialog } from "./dialogs/MusicLinksDialog";
// import { useLocation } from "react-router-dom";

type LinkType = {
	header: string;
	text: string;
	url: string;
};

export const Links = () => {
	// const location = useLocation();
	const [links, setLinks] = useState<LinkType[]>([]);
	const [loading, setLoading] = useState(true);
	const [mailDialogOpen, setMailDialogOpen] = useState<boolean>(false);
	const [musicDialogOpen, setMusicDialogOpen] = useState<boolean>(false);

	useEffect(() => {
		const getLinks = async () => {
			setLinks(await getCollection<LinkType>("links"));
			setLoading(false);
		};
		getLinks();
	}, []);

	// useEffect(() => {
	// 	const loadFacebookPixel = async () => {
	// 		const { default: ReactPixel } = await import(
	// 			"react-facebook-pixel"
	// 		);
	// 		ReactPixel.init("447389917815179", undefined, {
	// 			autoConfig: true,
	// 			debug: true,
	// 		});
	// 		ReactPixel.pageView();
	// 		ReactPixel.track("ViewContent", {
	// 			content_name: location.pathname,
	// 		});
	// 	};

	// 	loadFacebookPixel();
	// }, [location.pathname]);

	return (
		<>
			{mailDialogOpen && (
				<MailListDialog
					isOpen={mailDialogOpen}
					handleClose={() => {
						setMailDialogOpen(false);
					}}
				/>
			)}
			<MusicLinksDialog
				isOpen={musicDialogOpen}
				handleClose={() => {
					setMusicDialogOpen(false);
				}}
			/>
			<Box
				display="flex"
				justifyContent="flex-start"
				alignItems="center"
				flexDirection="column"
				minWidth="300px"
				minHeight="100vh"
				sx={{
					backgroundImage: `url(${backgroundImage})`, // Use the imported image
					backgroundSize: "cover", // Cover the entire box area
					backgroundRepeat: "no-repeat", // Don't repeat the image
					backgroundPosition: "center", // Center the background image
				}}
			>
				<Grid
					container
					display="flex"
					width={{ xs: "80%", md: "50%" }}
					minWidth="350px"
					padding={8}
				>
					<Grid
						item
						xs={12}
						display="flex"
						justifyContent="flex-start"
						alignItems="center"
						flexDirection="column"
						paddingBottom={8}
					>
						<img
							src={me}
							onLoad={() => {
								setLoading(false);
							}}
							height="200px"
							width="200px"
							alt="thats me"
							style={{
								overflow: "hidden",
								backgroundPosition: "center center",
								objectFit: "cover",
								opacity: loading ? 0 : 1,
								borderRadius: "50%",
								transition: "opacity 0.5s ease",
							}}
						/>
						<Typography variant="h4">@leo.lilley_</Typography>
					</Grid>
					<Grid item xs={12} display="flex" justifyContent="center">
						<Typography variant="h6">Links, click em up</Typography>
					</Grid>
					<Grid item xs={12} display="flex" justifyContent="center">
						<List sx={{ width: "100%" }}>
							{links.map(
								(linkData, index): JSX.Element => (
									<ListItem
										key={index}
										disablePadding
										sx={{ mb: 2, width: "100%" }}
									>
										<Button
											sx={{
												width: "100%",
												justifyContent: "center",
											}}
											variant="contained"
											color="inherit"
											onClick={() =>
												linkData.url !== ""
													? window.open(
															linkData.url,
															"_blank"
													  )
													: alert(
															"Event page not up yet!"
													  )
											}
										>
											<List>
												<Typography
													variant="h5"
													color="black"
													fontWeight="500"
												>
													{linkData.header}
												</Typography>
												<Typography
													variant="h6"
													color="black"
													fontWeight="400"
												>
													{linkData.text}
												</Typography>
											</List>
										</Button>
									</ListItem>
								)
							)}
						</List>
					</Grid>
					<Grid item xs={12}>
						<Box width="100%">
							<Paper
								sx={{
									backgroundColor: "primary.light",
									transition: "background-color 0.2s ease",
									padding: 1,
								}}
								elevation={2}
							>
								<ButtonGroup
									sx={{
										display: "flex",
										justifyContent: "space-around",
									}}
								>
									<Tooltip title="Lame">
										<Link
											href="https://www.instagram.com/leo.lilley_/"
											underline="hover"
											target="_blank"
											rel="noopener"
										>
											<IconButton size={"large"}>
												<InstagramIcon
													fontSize="inherit"
													color="inherit"
												/>
											</IconButton>
										</Link>
									</Tooltip>
									<Tooltip title="My where's my music?!?">
										<IconButton
											size={"large"}
											onClick={() =>
												setMusicDialogOpen(true)
											}
										>
											<AudiotrackIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
									<Tooltip title="Youtube???">
										<Link
											href="https://www.youtube.com/channel/UCoC_tpFLfX_R1KQJm7UKxdg"
											underline="hover"
											target="_blank"
											rel="noopener"
										>
											<IconButton size={"large"}>
												<YouTubeIcon fontSize="inherit" />
											</IconButton>
										</Link>
									</Tooltip>
									<Tooltip title="JOIN THE MAIL LIST!!!">
										<IconButton
											size={"large"}
											onClick={() =>
												setMailDialogOpen(true)
											}
										>
											<EmailIcon fontSize="inherit" />
										</IconButton>
									</Tooltip>
								</ButtonGroup>
							</Paper>
						</Box>
					</Grid>
				</Grid>
			</Box>
			<Box display="flex" alignItems="flex-end" justifyContent="center">
				<Typography>
					I've actually got no idea what I'm doing
				</Typography>
			</Box>
		</>
	);
};
