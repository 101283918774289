import { Box } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import me from "../images/me.jpg";
import { useScreenSize } from "./customHooks/useScreenSize";

interface FckenMeProps {
	setLoading: Dispatch<SetStateAction<boolean>>;
	loading: boolean;
}

export const FckenMe = ({ setLoading, loading }: FckenMeProps) => {
	const [_, height] = useScreenSize();
	return (
		<Box height={height}>
			<img
				src={me}
				onLoad={() => {
					setLoading(false);
				}}
				height="100%"
				width="100%"
				alt="thats me"
				style={{
					overflow: "hidden",
					backgroundPosition: "center center",
					objectFit: "cover",
					opacity: loading ? 0 : 1,
					transition: "opacity 0.5s ease",
				}}
			/>
		</Box>
	);
};
