import { BrowserRouter, Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { DefaultTheme } from "./Theme";
import { Main } from "./components/Main";
import { Links } from "./components/Links";
import { ItBegins } from "./components/ItBegins";

function App() {
	return (
		<ThemeProvider theme={DefaultTheme}>
			<CssBaseline />
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Main />}></Route>
					<Route path="/links" element={<Links />}></Route>
					<Route path="/it-begins" element={<ItBegins />}></Route>
				</Routes>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
