import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyB_TovexyTz4x1ioFrVv7xRRI7SUKhEfMo",
	authDomain: "leolilleyweb.firebaseapp.com",
	projectId: "leolilleyweb",
	storageBucket: "leolilleyweb.appspot.com",
	messagingSenderId: "200899623059",
	appId: "1:200899623059:web:a75712e749387d47fd7566",
	measurementId: "G-3M9MZMRMMH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Get a Firestore instance
export const firestore = getFirestore(app);

// Get a Storage instance
export const storage = getStorage(app);

export const getCollection = async <T>(
	collectionName: string
): Promise<T[]> => {
	const collectionRef = collection(firestore, collectionName);
	const querySnapshot = await getDocs(collectionRef);
	return querySnapshot.docs.map((doc) => doc.data() as T);
};