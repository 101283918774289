import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	Button,
	Link,
	Box,
} from "@mui/material";
import { ref, getDownloadURL } from "firebase/storage";
import { useState, useEffect } from "react";
import { storage } from "../../firebase";

interface MusicLinksDialogProps {
	isOpen: boolean;
	handleClose: () => void;
}

export const MusicLinksDialog = ({
	isOpen,
	handleClose,
}: MusicLinksDialogProps) => {
	const [loading, setLoading] = useState(true);
	const [coverImgURL, setCoverImgURL] = useState("");

	useEffect(() => {
		const storageRef = ref(storage, "itBegins/cover.jpg");
		getDownloadURL(storageRef).then((url) => {
			setCoverImgURL(url);
			setLoading(false);
		});
	}, []);

	return (
		<Dialog onClose={handleClose} open={isOpen}>
			<DialogTitle
				display="flex"
				align="center"
				flexDirection="column"
				justifyContent="center"
			>
				<Typography variant="h6Bold">
					Whatever floats your boat
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Link
							href="https://leolilley.bandcamp.com/"
							underline="hover"
							target="_blank"
							rel="noopener"
						>
							<Button
								variant="contained"
								color="inherit"
								size="large"
								fullWidth
							>
								BANDCAMP
							</Button>
						</Link>
					</Grid>
					<Grid item xs={12}>
						<Link
							href="https://open.spotify.com/artist/15re5mGlnEReWZfq93h4ze"
							underline="hover"
							target="_blank"
							rel="noopener"
						>
							<Button
								variant="contained"
								color="inherit"
								size="large"
								fullWidth
							>
								SPOTIFY
							</Button>
						</Link>
					</Grid>
					<Grid item xs={12}>
						<Link
							href="https://soundcloud.com/leolilley"
							underline="hover"
							target="_blank"
							rel="noopener"
						>
							<Button
								variant="contained"
								color="inherit"
								size="large"
								fullWidth
							>
								SOUNDCLOUD
							</Button>
						</Link>
					</Grid>
					<Grid item xs={12}>
						<Link
							href="https://music.apple.com/nz/artist/leo-lilley/1687044551"
							underline="hover"
							target="_blank"
							rel="noopener"
						>
							<Button
								variant="contained"
								color="inherit"
								size="large"
								fullWidth
							>
								APPLE MUSIC
							</Button>
						</Link>
					</Grid>
					<Grid item xs={12}>
						<Link
							href="https://music.youtube.com/channel/UCzM3_VRglVIpEfonTg-HgVA"
							underline="hover"
							target="_blank"
							rel="noopener"
						>
							<Button
								variant="contained"
								color="inherit"
								size="large"
								fullWidth
							>
								YOUTUBE MUSIC
							</Button>
						</Link>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};
