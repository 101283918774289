import { useState, useEffect } from "react";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { Box, Button, ImageList, ImageListItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { storage } from "../firebase";

interface ImageGalleryProps {
	ltSmall: boolean;
	columns: number;
}

const ImageGallery = ({ columns, ltSmall }: ImageGalleryProps) => {
	const [imageUrls, setImageUrls] = useState<string[]>([]);
	const [numImages, setNumImages] = useState(10);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const storageRef = ref(storage, "gigPhotos/");
		const getImages = async () => {
			const references = await listAll(storageRef);
			const promises = references.items.map((item) =>
				getDownloadURL(item)
			);
			setImageUrls(shuffleArray(await Promise.all(promises)));
			setLoading(false);
		};
		getImages();
	}, [numImages]);

	const loadMoreImages = () => {
		setNumImages(numImages + 10);
	};

	const shuffleArray = (array: string[]) => {
		for (let i = array.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[array[i], array[j]] = [array[j], array[i]];
		}
		return array;
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
		>
			{loading ? (
				<CircularProgress />
			) : (
				<>
					<ImageList
						cols={columns}
						gap={ltSmall ? 2 : 4}
						variant="masonry"
					>
						{imageUrls.slice(0, numImages).map((imageUrl) => (
							<ImageListItem key={imageUrl}>
								<img
									src={`${imageUrl}`}
									srcSet={`${imageUrl}`}
									alt={imageUrl}
									style={{
										borderBottomLeftRadius: 4,
										borderBottomRightRadius: 4,
										display: "block",
										width: "100%",
									}}
								/>
							</ImageListItem>
						))}
					</ImageList>
					<Button
						variant="contained"
						onClick={loadMoreImages}
						sx={{ width: "50%" }}
					>
						Load More!!
					</Button>
				</>
			)}
		</Box>
	);
};

export default ImageGallery;
