import { Box, Typography } from "@mui/material";
import { useScreenSize } from "../customHooks/useScreenSize";

interface SectionProps {
	barHeight: number;
	ltSmall: boolean;
	padding: number;
}

export const Contact = ({ barHeight, ltSmall, padding }: SectionProps) => {
	const [_, height] = useScreenSize();
	return (
		<Box minHeight={height - barHeight} padding={padding} id="CONTACT">
			<Typography variant="h2">CONTACT</Typography>
			<Box
				border="1px solid black"
				marginTop={padding}
				padding={padding}
				flex={1}
			>
				<Typography variant="body1" fontSize={ltSmall ? 18 : 20}>
					I'm always looking for people to collaborate with on music
					or coding.
				</Typography>
				<Typography
					variant="body1"
					fontSize={ltSmall ? 18 : 20}
					paddingBottom={4}
				>
					Hit me up if you reckon we could get up to some good.
				</Typography>
				<Typography variant="body1" fontSize={ltSmall ? 18 : 20}>
					Email: Leo.lml.lilley@gmail.com
				</Typography>
				<Typography variant="body1" fontSize={ltSmall ? 18 : 20}>
					Phone: 0212604361
				</Typography>
			</Box>
		</Box>
	);
};
