import { Box, CircularProgress, useTheme } from "@mui/material";
import { FloatingLinks } from "./FloatingLinks";
import { FckenMe } from "./FckenMe";
import { TopBar } from "./TopBar";
import { useState } from "react";
import { useScreenSize } from "./customHooks/useScreenSize";
import { useScrollHandler } from "./customHooks/useScrollHandler";
import { Bio } from "./sections/Bio";
import { Contact } from "./sections/Contact";
import { Photos } from "./sections/Photos";
import { Gigs } from "./sections/Gigs";

export const Main = () => {
	const theme = useTheme();
	const scroll = useScrollHandler();
	const [width, height] = useScreenSize();

	const [smallBarHeight, setSmallBarHeight] = useState<number>(0);
	const [currentBarHeight, setCurrentBarHeight] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);

	const sections = ["BIO", "GIGS", "PHOTOS", "CONTACT"];
	const ltLarge = theme.breakpoints.values.lg > width;
	const ltSmall = theme.breakpoints.values.sm > width;
	const pastTop =
		(height !== 0 && scroll > height - 300) || (ltLarge && scroll > 100);
	const globalPadding = ltSmall ? 4 : 8;

	const scrollOffset = currentBarHeight - smallBarHeight;

	return (
		<>
			<FckenMe setLoading={setLoading} loading={loading} />
			{loading && (
				<CircularProgress
					sx={{
						overflow: "hidden",
						backgroundPosition: "center center",
						position: "absolute",
						left: "45%",
						top: "45%",
					}}
					size="8rem"
				/>
			)}
			<Box
				sx={{
					opacity: loading ? 0 : 1,
					transition: "opacity 0.5s ease",
				}}
			>
				<FloatingLinks pastTop={pastTop} isSmall={ltSmall} />
				<TopBar
					sections={sections}
					setCurrentBarHeight={setCurrentBarHeight}
					barHeight={currentBarHeight}
					pastTop={loading ? true : pastTop}
					ltSmall={ltSmall}
					setSmallBarHeight={setSmallBarHeight}
					loading={loading}
					offset={scrollOffset}
				/>
				<Bio
					barHeight={smallBarHeight}
					ltSmall={ltSmall}
					padding={globalPadding}
				/>
				<Gigs
					barHeight={
						pastTop ? currentBarHeight : currentBarHeight / 2
					}
					ltSmall={ltSmall}
					padding={globalPadding}
				/>
				<Photos
					barHeight={
						pastTop ? currentBarHeight : currentBarHeight / 2
					}
					ltSmall={ltSmall}
					padding={globalPadding}
				/>
				<Contact
					barHeight={
						pastTop ? currentBarHeight : currentBarHeight / 2
					}
					ltSmall={ltSmall}
					padding={globalPadding}
				/>
			</Box>
		</>
	);
};
