import { Box, Grid, Typography } from "@mui/material";
import { useScreenSize } from "../customHooks/useScreenSize";
import ImageGallery from "../ImageGallery";

interface SectionProps {
	barHeight: number;
	padding: number;
	ltSmall: boolean;
}

export const Photos = ({ barHeight, padding, ltSmall }: SectionProps) => {
	const [_, height] = useScreenSize();
	return (
		<Grid
			minHeight={height - barHeight}
			container
			padding={padding}
			id="PHOTOS"
			display="flex"
			flexBasis="column"
			justifyContent="flex-start"
		>
			<Grid item xs={12}>
				<Typography variant="h2">PHOTOS</Typography>
			</Grid>
			<Grid item xs={12} paddingY={4}>
				<Typography variant="h6">
					yeah just some good times and shows I've played in the past
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				border="1px solid black"
				padding={ltSmall ? 1 : 4}
			>
				<Box
					maxHeight={ltSmall ? "none" : height - barHeight}
					minHeight={ltSmall ? "none" : height - barHeight}
					paddingRight={2}
					overflow="scroll"
				>
					<ImageGallery columns={ltSmall ? 1 : 3} ltSmall={ltSmall} />
				</Box>
			</Grid>
		</Grid>
	);
};
